const courses = [
  "Algorithms",
  "Data Structures",
  "Software Development",
  "Linux System Administration",
  "Network Systems",
  "Operating Systems",
  "Database Systems",
  "Data Science",
  "Artificial Intelligence",
  "Theory of Computation",
  " Discrete Structures",
  " Machine Learning",
];

export default courses;
